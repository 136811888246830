import { StaticImage as Img } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import serviceData from "~data/marketing/Service";
import ServiceWidget from "./Component/Widget";
import Service from "./style";

export default function ServiceSection() {
  return (
    <>
      <Service className=" border-default-color-2 bg-default">
        
        <Container>
          {/* Section Title */}
            <Service.Box mtLG="100px" mtMD="40px">
              <Row className="justify-content-center justify-content-md-start">
                {/* Single Service */}
                {serviceData.services.map(({ title, icon, text,id }) => {
                  return(<Col className="col-lg-4 col-xs-6 col-10" key={id}>
                  <ServiceWidget icon={icon} title={title} text={text}  id={id} mt="40px" /></Col>) 
                })}
              </Row>
            </Service.Box>
        </Container>
      </Service>
    </>
  )
}
//<br className="d-none d-xs-block" /> 
                    //<br className="d-none d-sm-block" />