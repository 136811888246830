import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import ServiceSection from "~sections/LandingPages/Connections/Service"
import ContentOne from '~sections/project/ContentOne'
import ContentTwo from '~sections/project/ContentTwo'
import ContentThree from '~sections/project/ContentThree'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import HeroSection from "~sections/LandingPages/Communicate/Hero/HeroSection"
import Testimonial from "~sections/customer-stories/Testimonial"
import { Helmet } from "react-helmet"
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Communicate() {
  return (
    <>
    <Helmet>
    <title>Say it loud and clear with voice notes.</title>
    <meta name="description" content="Receive the response you deserve when sending voice notes on LinkedIn. Find out how you can make the whole process ten times easier by using We-Connect today."/>
    <meta property="og:title" content="We-Connect | Say it loud and clear with voice notes. "/>
    <meta property="og:description" content="Receive the response you deserve when sending voice notes on LinkedIn. Find out how you can make the whole process ten times easier by using We-Connect today."/>
    <meta property="og:image" content="https://we-connect.io/we-connect_send-voice-notes-on-linkedin-page.png"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/send-voice-notes-on-linkedin"/>
    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="We-Connect | Say it loud and clear with voice notes. "/>
    <meta property="twitter:description" content="Receive the response you deserve when sending voice notes on LinkedIn. Find out how you can make the whole process ten times easier by using We-Connect today. "/>
    <meta property="twitter:image" content="https://we-connect.io/we-connect_send-voice-notes-on-linkedin-page.png"/>
        </Helmet>
    <PageWrapper headerConfig={header}>
      <HeroSection/>
      <ServiceSection/>
      <ContentOne/>
      <ContentTwo/>
      <ContentThree/>
      <Testimonial />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
