import Images from "~data/imageImports";
const serviceData = {
    services: [
        {
          id:"ms1",
          icon: Images.marketing.servicesIcon1,
          title: "Customizable Campaigns",
          text:
            "Try one of our nine fully customizable campaigns. Personalize your outreach message, set the frequency of follow-ups, randomize human-like behavior, and watch the leads flood in.",
        },
        {
          id:"ms2",
          icon: Images.marketing.servicesIcon2,
          
          title: "Intuitive Smart Sequences",
          text:
            "Set up a campaign that does it all, from visiting a profile and liking posts to sending connection requests and endorsing skills. Find out how smart sequences can boost your conversion rate today.",
        },
        {
          id:"ms3",
          icon: Images.marketing.servicesIcon3,
          
          title: "Enhanced Safety",
          text:
            "With We-Connect, each account receives its own dedicated IP address. We take extra measures to randomize delays between actions, enable sleep mode, auto-withdraw old connections, blacklist contacts, and automatically suppress duplicate actions.",
        },
      ]
  }
  export default serviceData;